.bg-warning-light {
  background: #fdd565 !important;
}
.bg-accent-2 {
  background: #a66d9f !important;
}
.q-btn.disabled {
  opacity: 0.4 !important;
}
a:not(.q-item:link,
.q-tab:link,
.q-btn):link {
  color: #368b4a;
}
a:not(.q-item:visited,
.q-tab:visited,
.q-btn):visited {
  color: #4893a9;
}
a:not(.q-item:hover,
.q-tab:hover,
.q-btn):hover {
  text-decoration-color: #4893a9;
  text-decoration-style: double;
}
a:not(.q-item:active,
.q-tab:active,
.q-btn):active {
  background: rgba(0,0,0,0.1);
  text-decoration-color: #4893a9;
}
.q-item.q-router-link--active,
.q-item--active {
  color: var(--q-color-secondary);
}
@media (hover: hover) and (pointer: fine) {
  .q-tr .row-action-buttons {
    visibility: hidden;
  }
  .q-tr:hover .row-action-buttons {
    visibility: visible;
  }
}
@media screen and (max-width: 600px) {
  .q-if {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 400px) {
  .q-if {
    font-size: 0.8rem;
  }
}
@media print {
  body {
    width: 100%;
  }
  body .q-layout {
    min-height: auto !important; /* important to overwrite inline-style */
  }
  body .q-page-container {
    padding: 3rem !important;
  }
  body .q-page-container .q-page {
    min-height: auto !important; /* important to overwrite inline-style */
  }
  body header,
  body .q-notifications,
  body .q-drawer,
  body .q-tabs,
  body .q-btn,
  body .q-table-bottom-item,
  body .q-tool-tip,
  body .q-menu {
    display: none;
  }
  body .q-table-control .q-select {
    display: none;
  }
}
@media only screen and (max-height: 400px) {
  header:not(.never-hide),
  footer:not(.never-hide) {
    display: none;
    height: 0;
    transition: ease all 0.5s;
  }
}
/*# sourceMappingURL=src/App.css.map */