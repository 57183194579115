// App Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Stylus variables found in Quasar's source Stylus files. Setting
// variables before Quasar's Stylus will use these variables rather than
// Quasar's default Stylus variable values. Stylus variables specific
// to the themes belong in either the variables.ios.styl or variables.mat.styl files.

// Check documentation for full list of Quasar variables


// App Shared Color Variables
// --------------------------------------------------
// It's highly recommended to change the default colors
// to match your app's branding.

$primary = #001318
$secondary = #368b4a
$secondary-transparent = rgba(57, 143, 83, .5)
$accent = #4893a9
$accent-transparent = rgba(72, 147, 169, .5)
$tertiary = $accent

//filters #4db166 
$dark-overlay = rgba(255,255,255, .9) // linear-gradient(180deg, rgba(0,19,24,0.9) 90%, rgba(0,19,24,0) 100%);


$neutral        = #E0E1E2
$positive       = #4caa59
$negative       = #9e2333
$info           = #4893a9
$warning        = #fabb4d
$warning-light  = #fdd565
$accent-2       = #a66d9f

.bg-warning-light{
  background: $warning-light !important;
}

.bg-accent-2{
  background: $accent-2 !important;
}